import { TDMSBase } from "../../base";
import { SupportedFileUploadTypes } from "./file.types";

/**
 * This class contains information that supports what our types look like
 *  when uploading data into the data store
 */
export class UploadOption extends TDMSBase {
  /**
   * The file type to utilize for this upload option
   */
  types!: SupportedFileUploadTypes[];

  /**
   * The name to call this upload processor.
   *
   * **THIS MUST BE UNIQUE**
   */
  name!: string;

  /** A description for the frontend to display to provide more context to the user than just a name. */
  description: string | undefined;

  /**
   * If this upload type can create a session.
   *
   * **Note: The backend will define this automatically if a session callback function is provided**
   */
  canCreateSessions: boolean = false;

  /**
   * If `canCreateSessions` is set to AND true but the upload endpoint has the potential of creating multiple sessions,
   *  you should set this to true so the frontend interface doesn't allow uploading of additional files as we wouldn't
   *  know what session to correlate them to.
   */
  canCreateMultipleSessions: boolean = false;

  /**
   * This value tells the uploader that this file can only be uploaded as a session creation callback if set. If set to false,
   *  the data store will allow you to not only upload it as a session creation but also as additional files.
   */
  restrictedToSessionCreation: boolean = true;

  /**
   * This value will restrict the data store from allowing more than one file to be uploaded matching this type into the data store.
   *  True means you can have more than one of this type, false means you can only have one.
   *
   * Additionally, setting true will also cause the backend to allow you to upload multiple files to this capability at once.
   */
  canContainMoreThanOne: boolean = false;

  /**
   * The name of the plugin this request belongs to
   *
   * **This will be auto assigned. You should not fill this in**
   */
  associatedPlugin!: string;

  /**
   * If this upload option can be uploaded by external users instead of only the backend controlling it.
   */
  canBeUploadedExternal: boolean = true;

  /** If a file can be uploaded into the data store only to be processed and thrown away, set this to true. This will only allow files to be processed and returned */
  usedOnlyForParsing: boolean = false;
}
