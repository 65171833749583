import { startCase } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { TDMSBase } from "../../base";

/**
 * This class tracks upload status messages as they occur during a data store upload. This helps identify
 *  extra processing that is occurring during an upload so the data store knows something is in progress and it's current state.
 */
export class DataStoreUploadStatus extends TDMSBase {
  /** This optional field is utilized when performing upload requests via the frontend. This allows us to link back to currently uploading progress. */
  uploadRequestId: string = uuidv4();
  /** The source of where this status update came from */
  source: string | undefined;
  /** Extra content to render under the processing message */
  extraContent: string | undefined;

  /** The current step of this stage. */
  step: string;
  /** The expected steps that we will encounter during this stage. In a dictionary of what stage each step belongs to. The array of steps will be in order but since the object will not be in order since it's a dict! */
  expectedSteps: { [key: string]: Array<string> };

  /** Should be the overarching stage like "Transcription". */
  stage: string;
  /** The names of the stages this upload status is going to go through, in the order of what they are expected. */
  expectedStages: Array<string> = [];

  /** The 0 - 100 percentage progress of the current step. -1 can be used to indicate an unknown progress. */
  progress: number;

  /** A rough estimate of how much time is remaining for this specific stage. Should be in seconds.*/
  estimatedTimeRemaining: number | undefined;

  /** A rough estimate of how much time is remaining for this entire request. Should be in seconds.*/
  estimatedTotalTimeRemaining: number | undefined;

  /** When this status update occurred */
  time = Date.now();

  constructor(
    progress: number,
    step: string,
    expectedSteps: { [key: string]: Array<string> } = {},
    stage: string,
    expectedStages: Array<string> = []
  ) {
    super();
    this.step = step;
    this.expectedSteps = expectedSteps;
    this.stage = stage;
    this.expectedStages = expectedStages;
    this.progress = progress;
  }

  /** Returns a pretty string of this data */
  get prettyString() {
    const progress = this.progress === -1 || this.progress == null ? "" : ` (${Math.round(this.progress)}%)`;
    return `${startCase(this.stage)}: ${startCase(this.step)}${progress}`;
  }

  get currentStageIndex() {
    return this.expectedStages.findIndex((x) => x.toLowerCase() === this.stage.toLowerCase());
  }

  /** Returns steps by the current stage */
  get stepsByStage() {
    return this.expectedSteps[this.stage];
  }

  /**
   * Returns the expected steps by their stage in the order as defined by the stage list.
   *
   * The stage name is index 1 of each array value and the steps are index 2
   */
  get orderedExpectedSteps(): Array<[string, Array<string>]> {
    return this.expectedStages.map((stage) => [stage, this.expectedSteps[stage]]);
  }

  get currentStepIndex() {
    return this.stepsByStage.findIndex((x) => x.toLowerCase() === this.step.toLowerCase());
  }

  /** Returns if this entire stage and step progress is complete */
  get isComplete() {
    return (
      this.currentStageIndex === this.expectedStages.length - 1 &&
      this.currentStepIndex === this.stepsByStage.length - 1 &&
      this.progress === 100
    );
  }

  /** Returns all step names as a string array */
  get allSteps() {
    const keys = Object.keys(this.expectedSteps);
    return keys.flatMap((x) => this.expectedSteps[x]);
  }

  /** Returns how many total steps there are for this multi stage status update */
  get totalSteps() {
    return this.allSteps.length;
  }

  /** Returns if the given step index is complete or not */
  stepIsComplete(stageIndex: number, stepIndex: number) {
    // If this is a lesser stage, we're good
    if (stageIndex < this.currentStageIndex) return true;
    else if (stageIndex === this.currentStageIndex) {
      // Check our current step index within the stage
      if (this.currentStepIndex > stepIndex) return true;
      if (this.currentStepIndex < stepIndex) return false;
      else return this.currentStepIndex === stepIndex && this.progress === 100; // Step index is equal to current index, check progress
    } else return false; // Stage index is greater than current stage index so we're definitely not complete
  }

  /** Based on the current step and stage, returns if the given stage index is complete or not. */
  stageIsCompleted(stageIndex: number) {
    return (
      // If we're past this stage we've completed
      this.currentStageIndex > stageIndex ||
      // If we're equal to the last stage and the last step is completed, we've also completed
      (this.currentStageIndex === stageIndex &&
        stageIndex === this.expectedStages.length - 1 &&
        this.currentStepIndex === this.stepsByStage.length - 1 &&
        this.progress === 100)
    );
  }

  /**
   * Given another set of upload status, merges the given stages and steps to the current stages and steps. This can be useful to help keep status updates from
   *  two completely separate functionalities somewhat together.
   * @param leadingElement The leading status and stages to place before the current
   */
  mergeStagesAndSteps(leadingElement: DataStoreUploadStatus) {
    this.expectedStages.unshift(...leadingElement.expectedStages);
    // Make sure everything is unique
    this.expectedStages = Array.from(new Set(this.expectedStages));
    this.expectedSteps = { ...leadingElement.expectedSteps, ...this.expectedSteps };
    return this;
  }

  /**
   * Based on the current status and the lastStatus given as well as the last status time, sets the estimated time remaining for this step.
   * @param lastStatus The last status message that was received
   * @param lastProgressTime The time the last status message was received
   */
  setEstimatedTime(lastStatus?: DataStoreUploadStatus) {
    if (this.estimatedTimeRemaining == null)
      if (lastStatus && lastStatus.step === this.step) {
        if (lastStatus.time) {
          const timeElapsed = Date.now() - lastStatus.time;
          const progressMade = this.progress - lastStatus.progress;
          if (progressMade !== 0)
            this.estimatedTimeRemaining = (Math.ceil(timeElapsed / progressMade) * (100 - this.progress)) / 1000;
          else this.estimatedTimeRemaining = lastStatus.estimatedTimeRemaining;
        }
      } else this.estimatedTimeRemaining = undefined;
  }
}
