import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Bookmark, BookmarkType, BookmarkTypeEnum, Session } from "@tdms/common";
import AudioMetricService from "@tdms/frontend/modules/audio/services/audio.metric.service";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { TranscriptionState } from "@tdms/frontend/modules/audio/store/transcription.state";
import { ChartJSDrawingHelper } from "@tdms/frontend/modules/charts/shared/plugins/drawing.helper";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import { MetricGridService } from "@tdms/frontend/modules/metrics/services/metric.grid.service";
import { selectSpecificMetricForCurrentSession } from "@tdms/frontend/modules/metrics/store/metric.selector";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { AudioPlayerComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { BehaviorSubject, Observable, combineLatest, map } from "rxjs";

@Component({
  selector: "audio-track",
  templateUrl: "./audio-track.component.html",
  styleUrls: ["./audio-track.component.scss"],
})
export class AudioTrackComponent extends SubscribingComponent implements AfterViewInit {
  /** The audio player that contains our content */
  @ViewChild("audioPlayer") audioPlayer: AudioPlayerComponent | undefined;

  /** Chart data for the waveform */
  metricGrid: MetricGridDataStore | undefined;

  /** The player's current time as a subject, separated so we can track it once the player is rendered */
  currentPlayerTime = new BehaviorSubject(0);

  constructor(
    private store: Store<TranscriptionState>,
    public audioService: AudioService,
    public themeService: ColorThemeService,
    private metricGridService: MetricGridService,
    private sessionService: SessionService
  ) {
    super();
    // Subscribe to seek requests
    this.addSubscription(
      this.audioService.seekTimeSubject.subscribe(async (time: number) => this.audioPlayer?.seekTo(time, true))
    );
    // Populate the metric
    this.createMetricConfigs();
  }

  ngAfterViewInit(): void {
    this.addSubscription(
      this.audioPlayer?.currentPlayerTime.subscribe(async (time) => this.currentPlayerTime.next(time))
    );
  }

  /** Creates the metric card information to display charts based on our audio file */
  async createMetricConfigs(showSteponBookmarks = false) {
    const playbackBookmarkObservable = this.getPlaybackBookmark();
    let bookmarkObservable: Observable<Bookmark[]> = playbackBookmarkObservable;
    // If we want step on bookmarks, try and grab them
    if (showSteponBookmarks) {
      bookmarkObservable = combineLatest([this.getSteponBookmarks(), playbackBookmarkObservable]).pipe(
        map((x) => [...x[0], ...x[1]])
      );
    }
    // Create the grid observable
    this.metricGrid = await this.metricGridService.observeMetricDataForSession(
      [AudioMetricService.WAVEFORM_CHART_CONFIG],
      undefined,
      bookmarkObservable
    );
  }

  /** Utilizing the comm frequency plugin, grab's communication step on bookmarks that exist */
  private getSteponBookmarks() {
    return this.store
      .select(selectSpecificMetricForCurrentSession("CommFrequency"))
      .pipe(map((x) => x?.bookmarks.filter((z) => z.bookmarkType.id === BookmarkTypeEnum.StepOn) ?? []));
  }

  /** Returns an observable of a bookmark that tracks the current playback time. */
  private getPlaybackBookmark(session: Session = this.sessionService.currentSession!) {
    const type = new BookmarkType(
      0,
      "Playback",
      ChartJSDrawingHelper.defaultColor,
      "vertical",
      false,
      true,
      undefined,
      false,
      true
    );
    const playbackBmk = new Bookmark(0, false, "Current Playback Time", type, session.startDate);
    return this.currentPlayerTime.pipe(
      map((currentTime) => {
        playbackBmk.startTime = new Date(session.startDate.getTime() + currentTime * 1000 + 1);
        return [playbackBmk];
      })
    );
  }

  /**
   * Changes notReady message depending on various states
   */
  get notReadyMessage(): string {
    return "Loading Audio Playback";
  }
}
