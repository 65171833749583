import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { DataStoreFile, UploadOption, Utility } from "@tdms/common";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";
import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";
import { selectDataStoreUploadOptions } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/**
 * The type of upload options we want to display in type format.
 */
export type DisplayTypes = "all" | "session-creation-only" | "exclude-session-creation";

/**
 * A component that will provide file uploading tracking and display so you can upload
 *  and remove different files
 */
@Component({
  selector: "data-store-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"],
})
export class FileUploaderComponent extends SubscribingComponent implements OnInit {
  /**
   * Our currently selected upload type in the select dropdown
   */
  @Input() selectedUploadType: UploadOption | undefined;

  /**
   * If we should hide the type selection for plugin and data source. This will also cause the option update to be skipped
   *  and rely heavily upon the Input value if selectedUploadType.
   */
  @Input() hideTypeSelection = false;

  /**
   * The plugin selected so we know what sub options to display
   */
  @Input() selectedPluginType: string | undefined;

  /**
   * The supported types of uploads that were grabbed from the backend
   */
  @Input() uploadTypes: UploadOption[] = [];
  nonFilteredUploadTypes: UploadOption[] = [];

  /**
   * The available plugins that upload types exist for
   */
  availablePluginUploads: string[] = [];

  /**
   * An array of what `selectedPluginType` has for data source options
   */
  uploadTypesForCurrentPlugin: UploadOption[] = [];

  /**
   * Our currently selected files.
   */
  @Input() currentFiles: TrackedFile[] = [];

  /**
   * An emitter that fires when the selected files are updated. This will contain
   *  an array of all of the selected files.
   */
  @Output() fileUpdate = new EventEmitter<TrackedFile[]>();

  /** An emitter that fires when the selected file is updateds
   */
  @Output() singleFileUpdate = new EventEmitter<TrackedFile>();

  /**
   * If we should allow multiple files to be uploaded
   */
  @Input() allowMultiple = true;

  /**
   * If uploading bulk files, this is used to allow for selection of multiple
   *  of the same file type.
   */
  @Input() isBulkUpload = false;

  /**
   * HTML reference to the hidden file input element so we can reset it if need be
   */
  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement> | undefined;

  /**
   * If we should only display the options for session creation capabilities
   */
  @Input() displayType: DisplayTypes = "all";

  /**
   * If the files in `currentFiles` should be displayed in the input box.
   */
  @Input() displayPreviouslyUploadedFiles = true;

  /** If we should display that this upload could act as a session creation */
  @Input() shouldDisplaySessionCreationActor = true;

  /** Tracks if the next file we attempt to upload is the later added session creation. */
  nextFileIsSessionCreation = false;

  constructor(private store: Store<DataStoreState>, private snackBar: MatSnackBar) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(
      this.store.select(selectDataStoreUploadOptions).subscribe((options) => {
        this.nonFilteredUploadTypes = options;
        if (!this.hideTypeSelection) this.updateUploadTypes();
        // Set default plugin type if empty
        if (this.selectedPluginType == null && this.availablePluginUploads[0] != null)
          this.selectedPluginTypingChange(this.availablePluginUploads[0]);
      })
    );
  }

  /** Updates global upload types to conform to options */
  updateUploadTypes() {
    this.uploadTypes = FileUploaderComponent.getUploadTypesForOptions(
      this.nonFilteredUploadTypes,
      this.displayType,
      this.nextFileIsSessionCreation
    );
    this.availablePluginUploads = [...new Set(this.uploadTypes.map((x) => x.associatedPlugin))];
    // Trigger updates to the dropdowns
    this.selectedPluginTypingChange(this.selectedPluginType);
  }

  /**
   * Given some upload options and a display type, returns the supported options for those params
   * @param options The upload options to parse through
   * @param displayType The display type to narrow the options to
   * @param actAsSessionCreation If we should act as session creation typings. This can flip how `exclude-session-creation` works.
   */
  static getUploadTypesForOptions(options: UploadOption[], displayType: DisplayTypes, actAsSessionCreation = false) {
    // We don't want options that can't actually be uploaded
    options = options.filter((x) => x.canBeUploadedExternal && !x.usedOnlyForParsing);
    // Filter display type data
    if (displayType === "session-creation-only") return options.filter((x) => x.canCreateSessions);
    else if (displayType === "exclude-session-creation" && !actAsSessionCreation)
      return options.filter((x) => !x.canCreateSessions || !x.restrictedToSessionCreation);
    else return options;
  }

  /**
   * Returns supported file types for the currently selected upload type
   */
  get supportedFileTypes() {
    return this.selectedUploadType?.types;
  }

  /**
   * Get's the text to display in the input box
   */
  get inputDisplay() {
    if (this.isBulkUpload) return "Upload Files...";
    else {
      return this.currentFiles.length === 0 || !this.displayPreviouslyUploadedFiles
        ? this.allowMultiple
          ? "Upload Files..."
          : "Upload File..."
        : this.currentFiles.map((x) => x.file.name).join(", ");
    }
  }

  /**
   * Updates the selected upload type with the given type
   */
  selectedUploadTypeChanged(type: UploadOption | undefined) {
    this.selectedUploadType = type;
    // Wipe input if we are not allowed to have multiple
    if (!this.allowMultiple) {
      this.currentFiles = [];
      if (this.fileInput) this.fileInput.nativeElement.value = "";
    }
    // Reset session creation if next doesn't support it
    if (this.nextFileIsSessionCreation && !type?.canCreateSessions) this.nextFileIsSessionCreation = false;
  }

  /**
   * Updates our selected plugin to filter down the data types
   */
  selectedPluginTypingChange(plugin: string | undefined) {
    this.selectedPluginType = plugin;
    if (plugin != null) {
      this.uploadTypesForCurrentPlugin = this.uploadTypes.filter((x) => x.associatedPlugin === plugin);
      this.selectedUploadTypeChanged(this.uploadTypesForCurrentPlugin[0]);
    }
  }

  /**
   * Performs some validation checking against the given files
   */
  private validateFileList(files: FileList | File[]): { continueProcessing: boolean; files?: File[] } {
    // Make sure our files are an array
    files = Array.from(files);
    const defaultReturn = { continueProcessing: true, files: files };
    // Next file validation
    if (files.length > 1 && this.nextFileIsSessionCreation) {
      this.snackBar.open(
        "You cannot upload more than one file when the next file is the session creation. Grabbing the first file.",
        "close",
        Configuration.ErrorSnackbarConfig
      );
      return { continueProcessing: true, files: [files[0]] };
    }
    // Handle checking for duplicate file names. Don't forget to consider plugins
    const nonDuplicateFiles = files.filter(
      (x) =>
        this.currentFiles.find((z) => {
          const globalFileName = z.fileDisplayName;
          const currentFileName = DataStoreFile.getPreferredFileName(x.name);
          return globalFileName === currentFileName && z.fileType.equals(this.selectedUploadType);
        }) == null
    );
    // If duplicates exist, remove them
    if (files.length !== nonDuplicateFiles.length) {
      files = nonDuplicateFiles;
      this.snackBar.open(
        "You cannot upload files with the same name. Filtering duplicates.",
        "close",
        Configuration.ErrorSnackbarConfig
      );
      defaultReturn.files = nonDuplicateFiles;
    }
    // Cases where we can't have more than one file
    if (!this.selectedUploadType?.canContainMoreThanOne && !this.isBulkUpload) {
      // One already exists
      if (this.currentFiles.find((x) => x.fileType.equals(this.selectedUploadType))) {
        // Replace the current file if this is session creation options
        if (this.displayType === "session-creation-only") {
          this.currentFiles = [];
          return { continueProcessing: true, files: [files[0]] };
        } else {
          this.snackBar.open("You cannot upload any more of this type.", "close", Configuration.ErrorSnackbarConfig);
          return { continueProcessing: false };
        }
      }
      // We are attempting to upload two at the same time
      else if (files.length > 1) {
        this.snackBar.open(
          "This upload source can only support one file of this type. Grabbing the first one selected.",
          "close",
          Configuration.ErrorSnackbarConfig
        );
        return { continueProcessing: true, files: [files[0]] };
      } else return defaultReturn;
    } else return defaultReturn;
  }

  /**
   * Handles whenever we have a new file set in the hidden file input
   */
  fileChange(event: Event & { target: { files: FileList | File[]; value: any } }) {
    let files: FileList | File[] = event.target?.files;
    const validationResult = this.validateFileList(files);
    // Only process if allowed
    if (validationResult.continueProcessing) {
      for (let i = 0; i < validationResult.files!.length; i++) {
        const selectedFile = validationResult.files![i];
        if (selectedFile != null) {
          let ext = Utility.getFileExtensionFromString(selectedFile.name);
          if (ext != null) {
            // Validate this is not a duplicate
            if (this.currentFiles.find((x) => x.file.name === selectedFile.name && x.file.size === selectedFile.size))
              return;
            if (DataStoreFile.verifyFileExtension(selectedFile.name, this.supportedFileTypes)) {
              const trackedFile = TrackedFile.fromPlain({
                fileType: this.selectedUploadType!,
                removable: true,
                isDelayedSessionCreation: this.nextFileIsSessionCreation,
              });
              trackedFile.file = selectedFile;
              this.currentFiles.push(trackedFile);
              this.fileUpdate.next(this.currentFiles);
              this.singleFileUpdate.next(this.currentFiles[this.currentFiles.length - 1]);
              this.nextFileIsSessionCreation = false;
            } else this.snackBar.open("That file type is not supported", "close", Configuration.ErrorSnackbarConfig);
          }
        }
      }
      this.nextFileIsSessionCreation = false;
    }
    // Reset hidden input content so it knows when data actually changes
    if (this.fileInput) this.fileInput.nativeElement.value = "";
  }

  // Checks to see if we are bulk uploading. If not, selectedUploadType.canContainMoreThanOne is returned.
  canSelectMultipleFiles() {
    if (this.selectedUploadType != null && !this.isBulkUpload) return this.selectedUploadType.canContainMoreThanOne;
    return this.isBulkUpload;
  }

  get canUploadNewSessionCreationFile() {
    return (
      this.shouldDisplaySessionCreationActor &&
      this.selectedUploadType?.canCreateSessions &&
      this.currentFiles.find((x) => x.fileType.canCreateSessions && x.isDelayedSessionCreation) == null
    );
  }

  get shouldAllowMultiple() {
    return this.allowMultiple ?? this.selectedUploadType?.canContainMoreThanOne ?? false;
  }
}
