import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { TimePickerComponent } from "@tdms/frontend/modules/shared/components/date-time-picker/time-picker/time-picker.component";
import { ServiceManager } from "@tdms/frontend/modules/shared/services/service.manager";
import { NgxColorsModule } from "ngx-colors";
import {
  AudioPlayerComponent,
  CellDisplay,
  CellTextEditorComponent,
  ChangelogComponent,
  ConfirmationDialogComponent,
  DateTimePickerComponent,
  DialogWrapperComponent,
  DivProgressComponent,
  GenericTableComponent,
  HelpDialogComponent,
  ImgWrapperComponent,
  ImprovedSelectComponent,
  SplitButtonComponent,
  TableBaseComponent,
  TabsBaseComponent,
  ThemeToggleComponent,
} from "./components";
import { ColorPickerComponent } from "./components/color-picker/color-picker.component";
import { CellEditor, EditableTableComponent } from "./components/tables/editable-table/editable-table.component";
import { ConfigDirective } from "./directive/config.directive";
import { NgVarDirective } from "./directive/ng-var.directive";
import { OverflowDirective, OverflowLastElementChildDirective } from "./directive/overflow.directive";
import { TextMaskDirective } from "./directive/text.mask.directive";
import PluginService from "./services/plugin.service";

/**
 * Array of our components so we can easily declare and export them without duplicating
 */
const SHARED_COMPONENTS = [
  GenericTableComponent,
  EditableTableComponent,
  TableBaseComponent,
  CellEditor,
  CellDisplay,
  CellTextEditorComponent,
  DivProgressComponent,
  DialogWrapperComponent,
  HelpDialogComponent,
  ConfirmationDialogComponent,
  SplitButtonComponent,
  ImprovedSelectComponent,
  NgVarDirective,
  ImgWrapperComponent,
  TabsBaseComponent,
  AudioPlayerComponent,
  ImprovedSelectComponent,
  ThemeToggleComponent,
  DateTimePickerComponent,
  TimePickerComponent,
  TextMaskDirective,
  OverflowDirective,
  OverflowLastElementChildDirective,
  ConfigDirective,
  ChangelogComponent,
  ColorPickerComponent,
];

@NgModule({
  declarations: SHARED_COMPONENTS,
  imports: [CommonModule, AngularMaterialModule, ReactiveFormsModule, FormsModule, NgxColorsModule],
  exports: SHARED_COMPONENTS,
  providers: [PluginService, ServiceManager],
})
export class SharedModule {}
