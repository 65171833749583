<shared-dialog-wrapper class="bookmark-dialog" [header]="dialogTitle">
  <div class="bookmark-dialog-container">
    <!-- Disable Indicator -->
    <div *ngIf="bookmarkForm.disabled" class="help-info disabled-info">This bookmark cannot be edited</div>
    <!-- Help -->
    <div *ngIf="isCreationDialog" class="help-info">
      Select your bookmark type and add any additional notes below. Once complete, click create and you'll be able to
      start drawing your bookmark on the chart.
    </div>
    <!-- Form -->
    <form class="bookmark-creation-form" [formGroup]="bookmarkForm" class="bookmark-creation-form">
      <div class="row">
        <div class="column">
          <!-- Type selection -->
          <shared-improved-select
            #select
            class="type-selection"
            [control]="$any(bookmarkForm.controls['bookmarkTypeSelection'])"
            [dropdownOptions]="selectableBookmarkTypes"
            dropdownName="Bookmark Type"
            [filterEnabled]="true"
            [maxFilterInputLength]="maxBookmarkTypeLength"
            [paginatorEnabled]="true"
            [itemsPerPage]="5"
            [clearable]="false"
            [shouldCleanName]="false"
            [allowMultipleSelections]="false"
            prefixOptionIcon="add"
            prefixOptionTooltip="Opens a dialog to add a new bookmark type"
            prefixOptionTooltipPosition="right"
            prefixOptionText="Bookmark Type"
            (prefixOptionCallback)="openInfoCreateWithNewBookmarkType($event)"
            [prefixOptionConfigDirectivePath]="'bookmark.allowTypeCreation'"
            [prefixOptionConfigDirectiveTooltip]="'Bookmark type creation is disabled'"
          >
            <!-- Replace the rendering of the standard data to include colored icons -->
            <ng-template
              replace="optionDisplay"
              let-value="value"
              let-valueClean="valueClean"
              let-isTopLevel="isTopLevel"
            >
              <span class="bookmark-type-option">
                <span class="grouping">
                  <mat-icon class="icon" [ngStyle]="{ color: value.color }">circle</mat-icon>
                  <span class="name">{{ value.name }}</span>
                </span>
                <!-- Edit button -->
                <button
                  *ngIf="!isTopLevel"
                  mat-icon-button
                  class="edit"
                  (click)="openInfo(value, 'edit'); select.select.close(); $event.stopPropagation()"
                  matTooltip="Opens a dialog to edit this bookmark type"
                  matTooltipPosition="right"
                  [configDisable]="['bookmark.allowTypeEditing', 'bookmark.allowTypeDeleting']"
                  configDisabledStateMessage="Bookmark type editing is disabled"
                >
                  <mat-icon>edit</mat-icon>
                </button>
              </span>
            </ng-template>
          </shared-improved-select>

          <!-- Checkbox for determining display on comparison interface -->
          <mat-checkbox
            formControlName="isCritical"
            class="critical-checkbox"
            matTooltip="If checked, bookmark will show in comparison interface"
            matTooltipPosition="right"
          >
            Critical
          </mat-checkbox>

          <!-- Start Time -->
          <shared-date-time-picker
            *ngIf="!isCreationDialog"
            [control]="$any(bookmarkForm.get('startDate'))"
            [textPrefix]="isRangeBookmark ? 'Start' : ''"
            [min]="currentSession?.startDate"
            [max]="currentSession?.endDate"
          ></shared-date-time-picker>

          <!-- End Time, if it exists -->
          <shared-date-time-picker
            *ngIf="!isCreationDialog && isRangeBookmark"
            [control]="$any(bookmarkForm.get('endDate'))"
            textPrefix="End"
            [min]="currentSession?.startDate"
            [max]="currentSession?.endDate"
          ></shared-date-time-picker>
        </div>

        <div class="column note-column">
          <!-- Note input -->
          <mat-form-field appearance="fill" color="accent" class="note-form">
            <mat-label>Notes</mat-label>
            <textarea matInput [cdkTextareaAutosize]="false" formControlName="note"></textarea>
          </mat-form-field>
        </div>
      </div>

      <!-- Button row -->
      <div class="button-container">
        <!-- Creation dialog buttons -->
        <button
          mat-raised-button
          color="accent"
          *ngIf="isCreationDialog"
          (click)="createBookmark(true)"
          [disabled]="!bookmarkForm.valid"
          [matTooltip]="
            !bookmarkForm.valid
              ? 'Please make sure all fields are filled in'
              : 'Similar to create but allows you to create a start and stop bookmark'
          "
        >
          Create Range
        </button>
        <button
          mat-raised-button
          color="accent"
          *ngIf="isCreationDialog"
          (click)="createBookmark()"
          [disabled]="!bookmarkForm.valid"
          [matTooltip]="!bookmarkForm.valid ? 'Please make sure all fields are filled in' : ''"
        >
          Create
        </button>
        <!-- Editing dialog buttons -->
        <button
          mat-raised-button
          color="warn"
          *ngIf="!isCreationDialog"
          (click)="deleteBookmark()"
          [matTooltip]="
            properties.deletable ?? true
              ? 'Opens a deletion confirmation modal for this bookmark'
              : 'Deletion is disabled'
          "
          [disabled]="!(properties.deletable ?? true)"
          [configDisable]="'bookmark.allowDeleting'"
          configDisabledStateMessage="Bookmark deletion is disabled"
        >
          Delete
        </button>

        <!-- Move button -->
        <shared-split-button
          [disabled]="bookmarkForm.disabled"
          [mainButtonClick]="startMove.bind(this, isRangeBookmark ? 'moveAll' : 'moveSingular')"
          mainButtonText="Move"
          mainButtonTooltip="Allows you to move where this bookmark is positioned"
          splitButtonTooltip="Additional range movement options"
          color="accent"
          *ngIf="!isCreationDialog"
          [displaySplit]="isRangeBookmark"
          [configDisable]="'bookmark.allowEditing'"
          configDisabledStateMessage="Bookmark editing is disabled"
        >
          <!-- Range movement options -->
          <button
            mat-menu-item
            (click)="startMove('moveStart')"
            matTooltip="Start moving the start bookmark only"
            matTooltipPosition="left"
            [disabled]="bookmarkForm.disabled"
            [configDisable]="'bookmark.allowEditing'"
            configDisabledStateMessage="Bookmark editing is disabled"
          >
            <mat-icon>first_page</mat-icon><span>Move Start</span>
          </button>
          <button
            mat-menu-item
            (click)="startMove('moveEnd')"
            matTooltip="Start moving the end bookmark only"
            matTooltipPosition="left"
            [disabled]="bookmarkForm.disabled"
            [configDisable]="'bookmark.allowEditing'"
            configDisabledStateMessage="Bookmark editing is disabled"
          >
            <mat-icon>last_page</mat-icon><span>Move End</span>
          </button>
        </shared-split-button>

        <button
          mat-raised-button
          color="accent"
          *ngIf="!isCreationDialog"
          (click)="saveBookmark()"
          [disabled]="!bookmarkHasChanges || !bookmarkForm.valid || bookmarkForm.disabled"
          [matTooltip]="bookmarkHasChanges ? 'Save\'s this current edited state.' : 'Bookmark has no changes'"
          [configDisable]="'bookmark.allowEditing'"
          configDisabledStateMessage="Bookmark editing is disabled"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</shared-dialog-wrapper>
