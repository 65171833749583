import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { Bookmark, BookmarkType, ChartConfiguration, CommonSetting, CommonSettingDescription } from "@tdms/common";
import { LegendDisplay } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display";
import { LegendDisplayObject } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display.object";
import { ZoomDomainUpdateEmitter } from "@tdms/frontend/modules/charts/timeline/timeline.component";
import { ColorMap } from "@tdms/frontend/modules/material/services/themes.service";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { TDMSTheme } from "@tdms/frontend/modules/shared/components";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { cloneDeep, kebabCase } from "lodash-es";
import { BehaviorSubject, Subscription } from "rxjs";
import {
  FrontendSupportedMetricType,
  MetricCardDataStore,
  MetricServiceDataStore,
} from "./models/metric.configuration";

/**
 * A generic card component to render the metric service data as given
 */
@Component({
  selector: "metric-card[dataStore][applicationTheme][totalChartTimeFrame]",
  templateUrl: "./metric-card.component.html",
  styleUrls: ["./metric-card.component.scss"],
})
export class MetricCardComponent<_ChartDataType extends FrontendSupportedMetricType>
  extends SubscribingComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() dataStore!: MetricCardDataStore;

  @Input() enabled!: boolean;

  @Input() graphTitleStart?: string;
  @Input() graphTitleEnd?: string;

  /**
   * The total time frame for this given chart. This is required because we must be able to detect the min and max domain values,
   *  in the event we don't have an scroll domain
   */
  @Input() totalChartTimeFrame!: [Date, Date];

  /**
   * A boolean to help track when a bookmark is actively being drawn
   */
  @Input() bookmarkIsDrawing!: boolean;
  /**
   * Auto bound function for adding new bookmarks
   */
  @Input() onAddBookmarkClicked?: { (bookmark: Bookmark): void } | undefined;
  /**
   * Auto bound function for updating bookmarks
   */
  @Input() onUpdateBookmarkClicked?: { (bookmark: Bookmark): void } | undefined;

  @Input() forceUpdateFilterDomain!: EventEmitter<{ domain: [Date, Date] | undefined; isResizeEvent: boolean }>;

  /**
   * A callable that will be called to determine the export prefix for our metric.
   */
  @Input() getExportPrefix?: (dataStore: MetricCardDataStore) => string;

  @Input() zoomDomainUpdater!: ZoomDomainUpdateEmitter;
  /** Tracks our current zoom domain */
  zoomDomain: [Date, Date] | undefined;

  /**
   * The theme that the overarching app is currently utilizing
   */
  @Input() applicationTheme!: TDMSTheme;

  @Input() bookmarkDrawingStatus!: BehaviorSubject<boolean>;

  @Input() gridLevelBookmarks!: Bookmark[];

  /**
   * Bookmark types available from the backend
   */
  @Input() bookmarkTypes: BookmarkType[] = [];

  /**
   * Since we can't decipher the "isTimeline" from just the metric configuration (as it is duplicated), we need the grid to tell us.
   */
  @Input() isTimeline!: boolean;

  _currentColorMap?: ColorMap;
  _colorMapSubscription?: Subscription;

  currentMergedBookmarks: Bookmark[] = [];

  /**
   * Current bookmark display enabled status, which is updated via the dataStore bookmarkEnabled observable.
   */
  bookmarksEnabled: boolean = true;

  bookmarksTooltip: string = "Bookmark Creation";

  /**
   * Tracks if this grid is rendered in the session comparison or not
   */
  @Input() isSessionComparison: boolean = false;

  /** Loaded settings for this specific metric. */
  settings: readonly (CommonSetting & CommonSettingDescription)[] = [];

  constructor(private settingsService: PluginSettingsService) {
    super();
    this.onLegendClicked = this.onLegendClicked.bind(this);
    this.updateColorMapSubscription = this.updateColorMapSubscription.bind(this);
  }

  ngOnInit(): void {
    this.mergeBookmarks();
    this.updateColorMapSubscription();
    this.addSubscription(this.zoomDomainUpdater?.subscribe((content) => (this.zoomDomain = content.domain)));
    this.addSubscription(
      this.dataStore.bookmarksEnabled.subscribe((enabled) => {
        this.bookmarksEnabled = enabled;

        if (enabled) {
          this.bookmarksTooltip = "Bookmark Creation";
        } else {
          this.bookmarksTooltip = "Bookmarks are disabled on this chart and cannot be added";
        }
      })
    );
    this.addSubscription(
      this.settingsService
        .observeCollectionSettings(this.configuration!.metricName)
        .subscribe((x) => (this.settings = x))
    );
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<MetricCardComponent<any>>): void {
    if (changes.dataStore != null) {
      this.updateColorMapSubscription();
      this.mergeBookmarks();
    }
    // Update bookmarks if the grid level ones change
    if (changes.gridLevelBookmarks) this.mergeBookmarks();
  }

  /**
   * Handle changes to the local bookmarks or grid level bookmarks, ensuring our chart gets the updated data.
   * We need to do this here to prevent over-rendering by creating a new array instance every time it's requested.
   * Instead, we only merge the data when angular detects changes.
   */
  mergeBookmarks() {
    this.currentMergedBookmarks = [
      ...(MetricServiceDataStore.getEnabledBookmarks(
        this.metric?.bookmarks,
        LegendDisplay.flattenOptions(this.legend || [])
      ) ?? []),
      ...this.gridLevelBookmarks,
    ];
  }

  /**
   * When our metric card data store changes, we need to release resources and subscribe to the new color map observable.
   */
  updateColorMapSubscription() {
    this._colorMapSubscription?.unsubscribe();

    this._colorMapSubscription = this.dataStore?.colorMap.subscribe((colorMap) => {
      this._currentColorMap = colorMap;
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._colorMapSubscription?.unsubscribe();
  }

  /** When the legend is clicked from a chart display, this callback will be hit to tell the actual legend to update */
  onLegendClicked(element: LegendDisplayObject) {
    if (this.dataStore.legend) {
      const updatedLegendValues = LegendDisplay.updateSpecificElement(cloneDeep(this.dataStore.legend.value), element);
      // Inform others that use this subject
      this.dataStore.legend.next(updatedLegendValues);
    }
  }

  /**
   * Returns the prefix to add to the export data for the charts
   */
  get exportPrefixText() {
    if (this.dataStore == null || this.getExportPrefix == null) return null;
    return kebabCase(this.getExportPrefix(this.dataStore)) + "-";
  }

  get metric() {
    return this.dataStore?.metric;
  }

  get isLoading() {
    /**
     * We consider a chart still "loading" if no color values are available yet. But if no colors will ever be loaded,
     *  because there's no data, we need to short circuit this check and allow that to be displayed.
     */
    if (this.metric?.dataIsNotAvailable === true) return false;
    else return this.colorMap == null || this.colorMap.length === 0;
  }

  get chartDataIsCalculating() {
    return this.metric?.isCalculating;
  }

  get configuration(): ChartConfiguration | null {
    if (this.dataStore == null) return null;
    return this.dataStore.configuration;
  }

  get chartType() {
    return this.configuration?.type;
  }

  get data() {
    return this.metric?.data as any;
  }

  get colorMap() {
    return this._currentColorMap;
  }

  get bookmarks() {
    return this.currentMergedBookmarks;
  }

  get legend() {
    return this.dataStore?.legend?.value;
  }

  /** Returns the line chart domain to utilize for display */
  get lineChartDomain() {
    return this.zoomDomain || this.totalChartTimeFrame;
  }
}
