<shared-dialog-wrapper
  class="role-mapping-wrapper"
  header="Role Mapping"
  [backdropClickCallback]="properties.onCancel"
  [closeDialogCallback]="properties.onCancel"
>
  <div class="role-mapping-container">
    <div class="header instructions">
      {{
        properties.request.displayMessage ||
          "The file uploaded doesn't contain the necessary metadata to create sessions. Please provide the additional information below by customizing role names and associated sessions."
      }}
    </div>

    <div class="info-container">
      <!-- Render each roles set of information -->
      <div class="info" *ngFor="let mapping of this.roleMapDialogPropMappings; index as i">
        <!-- Delete button for manually added roles -->
        <button
          *ngIf="mapping.createdManually"
          mat-flat-button
          color="warn"
          class="manual-delete-role"
          matTooltip="Removes this manually added role from this session"
          matTooltipPosition="left"
          (click)="removeScratchRole(mapping)"
        >
          <mat-icon class="icon">delete_forever</mat-icon>
          <span class="username">Delete</span>
        </button>
        <!-- Channel -->
        <mat-form-field
          class="channel field"
          appearance="fill"
          color="accent"
          *ngIf="mapping.mapping && mapping.mapping.channel !== -1"
        >
          <!-- We don't display roles with a value of -1. This is to separate manually added roles vs data derived roles. -->
          <mat-label>Channel</mat-label>
          <input
            matInput
            [value]="mapping.mapping.channel"
            required
            [disabled]="!properties.request.allowChannelEdits || mapping.createdManually"
            type="number"
            min="0"
            oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
          />
        </mat-form-field>
        <!-- Grouping -->
        <mat-form-field class="group field" appearance="fill" color="accent">
          <mat-label>Session Grouping</mat-label>
          <input
            matInput
            [(ngModel)]="mapping.mapping.sessionGrouping"
            required
            [disabled]="!properties.request.allowSessionGroupEdits || mapping.createdManually"
          />
        </mat-form-field>
        <!-- Name -->
        <mat-form-field class="name field" appearance="fill" color="accent">
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="mapping.mapping.userGivenName" required (keydown.enter)="submit()" />
        </mat-form-field>
        <!-- User Association -->
        <shared-improved-select
          [selectedValue]="getModifiedUserById(mapping)"
          (selectedValueChanged)="handleUserUpdate(mapping, $event)"
          [dropdownOptions]="availableUsers"
          dropdownName="Associated User"
          [filterEnabled]="true"
          [paginatorEnabled]="true"
          [itemsPerPage]="5"
          [clearable]="false"
          [shouldCleanName]="false"
          [allowMultipleSelections]="false"
          [prefixButtonIcon]="canCreateUsers ? 'add' : undefined"
          prefixButtonTooltip="Create New User"
          (prefixButtonCallback)="openUserCreation(mapping)"
          prefixButtonConfigDirectivePath="user.allowCreation"
          prefixButtonConfigDirectiveTooltip="User creation is disabled"
          class="user-selector"
        ></shared-improved-select>
        <!-- Playback, if available -->
        <shared-audio-player
          class="audio-player"
          *ngIf="mapping.mapping.playbackPath && audioStore[mapping.mapping.playbackPath]"
          [src]="audioStore[mapping.mapping.playbackPath].url"
        ></shared-audio-player>
      </div>
    </div>

    <!-- Buttons -->
    <div class="buttons" *ngVar="validityError as validityMessage">
      <div class="add-participant">
        <button
          mat-raised-button
          color="accent"
          *ngIf="this.properties.request.roleMapEditing"
          matTooltip="Add a user/role to this session. No metrics will be generated for them, 
          but they will be associated with this session."
          (click)="addScratchRole()"
        >
          <mat-icon>person_add_alt_1</mat-icon>
          Add Session Participant
        </button>
      </div>
      <div class="submit-cancel">
        <!-- Cancel -->
        <button mat-raised-button color="warn" (click)="properties.onCancel()">Cancel</button>
        <!-- Submit -->
        <button
          mat-raised-button
          color="accent"
          (click)="submit()"
          [disabled]="validityMessage != null"
          [matTooltip]="validityMessage || ''"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</shared-dialog-wrapper>
