<!-- Auto customize Y axis tooltip display -->
<shared-div-progress-component
  [ready]="!isCalculating && !isLoading"
  [notReadyMessage]="isLoading ? 'Loading...' : 'Calculating...'"
>
  <div class="chart-container" [ngStyle]="{ visibility: isLoading ? 'hidden' : 'inherit' }">
    <!-- Render title row -->
    <div class="chart-title-row" [ngClass]="{ 'legend-not-rendered': !shouldDisplayLegend }">
      <div class="start-row">
        <!-- Start title text -->
        <span class="start" [ngClass]="{ hidden: graphTitleStart == null }"> {{ graphTitleStart }}</span>
      </div>
      <div class="title" *ngIf="configuration.title && configuration.showTitle">{{ configuration.title }}</div>
      <!-- Button row -->
      <div class="end-row">
        <!-- Render end row buttons -->
        <ng-container *ngFor="let button of headerButtons">
          <button
            mat-button
            *ngIf="button.isVisible ? button.isVisible() : true"
            (click)="standardButtonClicked.call(this, button, $event)"
            [disabled]="!(button.enabled ? button.enabled() : true)"
            [matTooltip]="button.tooltip ?? ''"
            [ngClass]="{ active: button.isActive }"
            class="end-row-button"
            [configDisable]="button.configDirective?.path"
            [configDisabledStateMessage]="button.configDirective?.tooltip"
          >
            <mat-icon>{{ button.icon }}</mat-icon>
          </button>
        </ng-container>

        <!-- Kebab menu -->
        <button *ngIf="kebabMenuEnabled" mat-button [matMenuTriggerFor]="chartBaseKebab">
          <mat-icon>more_vert</mat-icon>
        </button>
        <!-- Contents for Kebab -->
        <mat-menu #chartBaseKebab="matMenu" class="chart-base-kebab">
          <!-- Render kebab buttons -->
          <ng-container *ngFor="let button of kebabButtons">
            <button
              mat-menu-item
              *ngIf="button.isVisible ? button.isVisible() : true"
              (click)="kebabButtonClicked.call(this, button, $event)"
              [matTooltip]="button.tooltip ?? ''"
              matTooltipPosition="left"
              [disabled]="!(button.enabled ? button.enabled() : true)"
              [ngClass]="{ active: button.isActive }"
              class="end-row-kebab-button"
              [configDisable]="button.configDirective?.path"
              [configDisabledStateMessage]="button.configDirective?.tooltip"
            >
              <mat-slide-toggle
                *ngIf="button.icon === 'toggle'"
                [checked]="$any(button).toggleStatus"
                (change)="kebabButtonClicked.call(this, button, $event)"
                (click)="$event.stopPropagation()"
              ></mat-slide-toggle>
              <mat-icon *ngIf="button.icon !== 'toggle'">{{ button.icon }}</mat-icon>
              <span class="title">{{ button.title }}</span>
            </button>
          </ng-container>
        </mat-menu>

        <!-- End title text -->
        <span class="end" [ngClass]="{ hidden: graphTitleStart == null }">
          {{ graphTitleEnd }}
        </span>
      </div>
    </div>

    <!-- Display the legend -->
    <charts-custom-legend
      *ngIf="shouldDisplayLegend && customLegendValues && customLegendValues.length > 0"
      [values]="customLegendValues!"
      [clickCallback]="customLegendClickCallback!"
      [colorGetter]="getColor.bind(this)"
    ></charts-custom-legend>

    <!-- Handle charts that have no data -->
    <div *ngIf="dataIsNotAvailable" class="empty-data">No Data Available</div>

    <!-- Display our chart -->
    <div
      [classList]="'charts-inner-container ' + chartType"
      [ngStyle]="{ display: dataIsNotAvailable ? 'none' : 'inherit' }"
    >
      <canvas [id]="chartId" class="chart-canvas"></canvas>
    </div>

    <!-- Footer text -->
    <div class="footer" *ngIf="configuration.footer">{{ configuration.footer }}</div>
  </div>
</shared-div-progress-component>
