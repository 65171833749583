import { Injectable } from "@angular/core";
import { MemoizedSelector, Store } from "@ngrx/store";
import { Bookmark, ChartConfiguration, filterNulls, MetricBaseSettings, Session } from "@tdms/common";
import { LegendDisplay } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import {
  MetricCardDataStore,
  MetricServiceDataStore,
} from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import {
  selectMetricConfiguration,
  selectMetricDataForCurrentSession,
  selectMetricDataForGivenSession,
} from "@tdms/frontend/modules/metrics/store/metric.selector";
import { MetricState } from "@tdms/frontend/modules/metrics/store/metric.state";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { selectCurrentSession, selectSessionFromId } from "@tdms/frontend/modules/session/store/session.selector";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { BehaviorSubject, combineLatest, firstValueFrom, from, map, mergeMap, Observable, of } from "rxjs";
import { LegendService } from "./legend.service";
/**
 * The Metric Grid Service is responsible for generating the `MetricGridDataStore` objects that the Metric Grid needs in order to display it's data.
 * It's primary function is to bridge the gap between ChartConfiguration and `MetricServiceDataStore`
 * to orchestrate the data for the low level components appropriately.
 */
@Injectable({ providedIn: "root" })
export class MetricGridService extends Service {
  constructor(
    private store: Store<MetricState>,
    private themeService: ColorThemeService,
    private sessionService: SessionService,
    private settingsService: PluginSettingsService,
    private legendService: LegendService
  ) {
    super();
  }

  /**
   * Create an observable that returns the latest `MetricGridDataStore` populated with all the requisite data as it loads.
   * the observable first emits an empty data store with no requisite fields, but mutates the fields and emits as the data becomes available.
   * @param extraBookmarks Extra bookmarks to include in our render
   * @returns A stream containing the latest metric grid data available.
   */
  public async observeMetricDataForSession(
    configurations: ChartConfiguration[],
    session = this.sessionService.currentSession!,
    extraBookmarks?: Observable<Bookmark[]>
  ): Promise<MetricGridDataStore> {
    const sessionObservable = this.store.select(selectSessionFromId(session.id));
    const sessionStartObservable = sessionObservable.pipe(filterNulls).pipe(map((x) => x.startDate));
    const sessionEndObservable = sessionObservable.pipe(filterNulls).pipe(map((x) => x.endDate));
    const sessionBookmarkObservable = this.store
      .select(selectCurrentSession)
      .pipe(map((session) => session?.bookmarks ?? []));
    const bookmarkObservable = extraBookmarks
      ? combineLatest([sessionBookmarkObservable, extraBookmarks]).pipe(map((x) => [...x[0], ...x[1]]))
      : sessionBookmarkObservable;

    const rolesBasedLegend = this.legendService.observableToBehavior(
      this.legendService.getRoleBasedLegendWithStore(session),
      this.legendService.getRoleBasedLegend(session)
    );

    // Handle no existing configurations
    if (configurations.length === 0)
      return new MetricGridDataStore(
        [],
        undefined,
        of(false),
        sessionStartObservable,
        sessionEndObservable,
        bookmarkObservable
      );

    /// Create an empty array to contain all the observables we are going to create.
    const observables = [];

    /// Find the metric that should be tied to the timeline and append it to our list of configurations.
    const sessionTimelineConfiguration = this.findSessionTimelineMetric(configurations);

    /// Create a separate observable for the timeline card.
    const timelineObservable =
      sessionTimelineConfiguration == null
        ? undefined
        : this.createObservableFor(sessionTimelineConfiguration, session, undefined, true);

    for (let configuration of configurations) {
      observables.push(this.createObservableFor(configuration, session, rolesBasedLegend));
    }

    return new MetricGridDataStore(
      observables,
      timelineObservable,
      of(true),
      sessionStartObservable,
      sessionEndObservable,
      bookmarkObservable
    );
  }

  /**
   * Utility function to get the current metric configurations from the store.
   * @returns A promise of the List of ChartConfigurations.
   */
  public getMetricConfigurations() {
    return firstValueFrom(this.store.select(selectMetricConfiguration));
  }

  /**
   * Find the metric that should be used for our timeline out of all the available configurations.
   * Specifically, this will find the *first* metric that is flagged as shouldBeUsedForTimeline.
   * Throws an Error if no metric is flagged as timeline worthy.
   * @param configurations The available chart configurations.
   * @returns The matched metric configuration.
   */
  public findSessionTimelineMetric(configurations: ChartConfiguration[]) {
    const sessionMetric = configurations.find((configuration) => configuration.shouldBeUsedForTimeline);
    if (sessionMetric == null) return undefined;

    return new ChartConfiguration({
      getBySessionIdQueue: "",
      position: -1,
      showAnimations: false,
      title: "Timeline",
      showBookmark: true,
      displayLegend: false,
      exportAllowed: false,
      metricName: sessionMetric.metricName,
      shouldBeUsedForTimeline: true,
      showXAxis: false,
      showYAxis: false,
    });
  }

  /**
   * Create a metric data observable for the given chart configuration, along with a custom legend generator that should be used.
   * @param configuration The chart configuration to observe.
   * @param isTimeline If this is the timeline configuration or not. Setting to true will cause bookmarks to always be displayed.
   * @returns The created observable that will provide data updates for that metric.
   */
  public createObservableFor(
    configuration: ChartConfiguration,
    session?: Session,
    legend?: BehaviorSubject<LegendDisplay[]>,
    isTimeline = false
  ) {
    let selector: MemoizedSelector<MetricState, MetricServiceDataStore<any> | undefined>;

    if (session == null) selector = selectMetricDataForCurrentSession(configuration);
    else selector = selectMetricDataForGivenSession(configuration, session.id);

    /// Create an observable for the metric matching this configuration.
    const observable = combineLatest([
      this.store.select(selector).pipe(filterNulls),
      this.store.select(selectSessionFromId(session?.id)),
    ])
      .pipe(
        map((data) => {
          const metric = data[0].clone();
          const session = data[1];
          if (session != null && session.isProcessing) metric.isCalculating = true;
          /// Map the color map for the selected metric data.
          const colorMap = this.themeService.observeColorsFor(metric.data);
          const enabledObservable = this.getChartEnabledObservable(configuration);
          const bookmarkEnabledObservable = this.getBookmarkEnabledObservable(configuration, isTimeline);
          return new MetricCardDataStore(
            metric,
            configuration,
            colorMap,
            enabledObservable,
            bookmarkEnabledObservable,
            legend
          );
        })
      )
      .pipe(filterNulls);

    return observable;
  }

  /**
   * Returns an observable that tracks the enabled status of the bookmarks displayed setting.
   * @param configuration The configuration for the chart to track
   * @param isTimeline If this is the timeline configuration or not. Setting to true will cause bookmarks to always be displayed.
   */
  getBookmarkEnabledObservable(configuration: ChartConfiguration, isTimeline = false): Observable<boolean> {
    if (isTimeline) return of(true);
    else
      return this.settingsService.observeSettingValue(
        configuration.metricName,
        MetricBaseSettings.Names.bookmarksDisplayed
      );
  }

  /**
   * Returns an observable for tracking the enabled status of a chart based on settings changes
   * @param configuration The configuration for the chart to track so we can get the metric name.
   *
   * In the event the setting for chart enabled doesn't exist, we return an observable of true.
   */
  getChartEnabledObservable(
    configuration: ChartConfiguration,
    setting = MetricBaseSettings.Names.chartEnabled
  ): Observable<boolean> {
    return from(this.settingsService.hasSetting(configuration.metricName, setting)).pipe(
      mergeMap((hasSetting) => {
        if (hasSetting) return this.settingsService.observeSettingValue<boolean>(configuration.metricName, setting);
        else return of(true);
      })
    );
  }
}
